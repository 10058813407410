.faq-container {
    height: 100vh;
    max-width: 100vw;
    position: relative;
    top: 220px;
    margin-bottom: 20%;
}

.faq-header {
    text-align: center;
    font-size: 38px;
    font-weight: 700;
}

.faqSection {
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 20px;
}

@media screen and (max-width: 600px) {
    .faq-container {
       margin-top: 85%;
       margin-bottom: 160%;
    }
}