@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.workExpApproach {
    height: 120vh;
    max-width: 100vw;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.workTitle {
    height: auto;
    font-weight: 750;
    font-size: 33px;
    width: 100%;
    padding-left: 520px;
    padding-right: 520px;
}

.workPara {
    color: #7b8c88;
    padding-left: 24%;
    padding-right: 24%;
    font-size: 17px;
    line-height: 29px;
    margin-top: 20px;
}

.card {
    /* background-color: #F2FAF6; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    /* Added margin to create space between cards */
    width: 80%;
    /* Adjust the width as needed (e.g., 80% or 90%) */
    max-width: 350px;
    /* Set a max width for better control */
    height: auto;
    /* Adjusted height to fit content */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider .slick-slide {
    display: flex;
    justify-content: center;
    /* Center the card within the slider */
}

.slider-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center the entire slider */
    width: 80%;
}

.slider {
    display: flex;
    justify-content: center;
}


.card-header {
    margin-bottom: 10px;
}

.project-type {
    display: inline-block;
    font-size: 13px;
    padding: 5px 10px;
    color: white;
    /* background-color: #07634C; */
    border-radius: 9999px;
    font-weight: 700;
}

.project-image {
    max-width: 200px;
    margin: 20px 0;
}

.tech-stack {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.tech-item {
    padding: 4px 12px;
    border-radius: 9999px;
    border: 2px solid black;
    font-size: 13px;
}

.slider {
    display: flex;
    justify-content: space-evenly;
}

.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .carousel .slick-slide {
        display: flex;
        justify-content: center;
        width: 100% !important;
        margin: 0;
    }
    .workTitle {
        height: auto;
        font-weight: 700;
        font-size: 26px;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .workPara {
        color: #7b8c88;
        padding-left: 10%;
        padding-right: 10%;
        font-size: 14px;
        line-height: 29px;
        margin-top: 20px;
    }
    .card{
        width: 100%;
        margin: 0;
    }

    .workExpApproach {
        margin-top: 90%;
    }
}