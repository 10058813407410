.comparison-container {
    height: 110vh;
    max-width: 100vw;
    margin-bottom: 20%;
}

.comparison-header {
    padding-left: 22%;
    padding-right: 22%;
    font-size: 33px;
    font-weight: 750;
}

.comparision-para {
    padding-left: 22%;
    padding-right: 22%;
    color: #7B8A87;
    line-height: 30px;
    font-size: 15px;
    text-align: center;
}

.comparision-table-cell {
    text-align: center;
    padding: 16px;
    font-weight: 700;
    font-size: 15px;
    color: #000;
    border-bottom: 1px solid #E2E8F0;
}

.comparision-table-header {
    background-color: rgb(91, 189, 255);
    color: #000;
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid rgb(91, 189, 255);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}


.comparsion-table-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    margin-top: 13%;
}

.comparison-table {
    width: 100%;
    max-width: 900px;
    border-collapse: collapse;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}


.checkIconComparision {
    color: #03C191;
    font-size: 20px;
    transition: transform 0.2s;
}

.crossIconComparision {
    color: #B5C3BF;
    font-size: 20px;
    transition: transform 0.2s;
}

.comparision-feature-cell {
    font-size: 18px;
    font-weight: 500;
}

.tech-guy-2-comparision {
    position: absolute;
    z-index: 10;
    left: 20%;
    width: 30%;
}

@media screen and (min-width: 1800px) {
    
    .tech-guy-2-comparision{
        width: 29%;
        left: 24%;
    }
}


@media screen and (max-width: 1000px) {
    .tech-guy-2-comparision {
        position: absolute;
        z-index: 10;
        left: 10%;
        width: 31%;
    }
}

@media screen and (max-width: 600px) {
    /* .comparision-feature-cell{
        font-size: 18px;
        font-weight: 500;
    } */

    .comparison-header {
        padding-left: 5%;
        padding-right: 5%;
        font-size: 24px;
    }

    .comparison-container {
        margin-top: 60%;
    }

    .comparision-para {
        padding-left: 5%;
        padding-right: 5%;
        color: #7B8A87;
        line-height: 30px;
        font-size: 13px;
        text-align: center;
    }


    .comparision-table-cell {
        text-align: center;
        padding: 2px;
        font-weight: 500;
        font-size: 12px;
        color: #000;
        border-bottom: 1px solid #E2E8F0;
    }

    .comparision-table-header {
        background-color: rgb(91, 189, 255);
        color: #000;
        padding: 8px;
        font-size: 14px;
        font-weight: bold;
        border-bottom: 2px solid rgb(91, 189, 255);
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .checkIconComparision {
        font-size: 15px;
    }

    .crossIconComparision {
        font-size: 15px;
    }

    .tech-guy-2-comparision {
        /* width: 60%;
        left: -5%;
        bottom: 111%; */
        left: 8%;
        transform: translateY(39px);
    }

    .comparsion-table-container {
        margin-top: 20%;
        /* margin-left: 2%;
        margin-right: 2%; */
    }
}