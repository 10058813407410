.manrope-hero-section {
    font-family: "Manrope", sans-serif;
}

.rubik-hero-section {
    font-family: "Rubik", sans-serif;
}

.tech-guy-image-1 {
    position: absolute;
    top: 125%;
    /* Centers vertically */
    left: 50%;
    /* Centers horizontally */
    transform: translate(-50%, -50%);
    /* Fine-tunes centering */
    width: 450px;
    /* Adjust size as needed */
    z-index: 10;
    /* Places it above other content */

}

/* Animation for html-tag-icon */
@keyframes whimsicalBounce {

    0%,
    100% {
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
    }

    25% {
        transform: translate(-50%, -50%) scale(1.1) rotate(10deg);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.2) rotate(-10deg);
    }

    75% {
        transform: translate(-50%, -50%) scale(1.1) rotate(10deg);
    }
}

.html-tag-icon {
    position: absolute;
    top: 100%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100px;
    animation: whimsicalBounce 2s infinite;
}

/* Animation for curly-icon */
@keyframes colorShiftSpin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 1;
        filter: hue-rotate(0deg);
    }

    25% {
        filter: hue-rotate(90deg);
    }

    50% {
        transform: translate(-50%, -50%) rotate(180deg);
        opacity: 0.7;
        filter: hue-rotate(180deg);
    }

    75% {
        filter: hue-rotate(270deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
        opacity: 1;
        filter: hue-rotate(360deg);
    }
}

.curly-icon {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100px;
    animation: colorShiftSpin 3s infinite;
}

/* Animation for ham-burger-icon */
@keyframes shakeAndFlip {

    0%,
    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    10% {
        transform: translate(-50%, -50%) rotate(10deg) translateX(5px);
    }

    20% {
        transform: translate(-50%, -50%) rotate(-10deg) translateX(-5px);
    }

    30% {
        transform: translate(-50%, -50%) rotate(5deg) translateX(3px);
    }

    40% {
        transform: translate(-50%, -50%) rotate(-5deg) translateX(-3px);
    }

    50% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.ham-burger-icon {
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100px;
    animation: shakeAndFlip 1.5s infinite;
}







.enroll-button-hero-section {
    border: 4px solid #ec4899; /* pink-500 */
    background-color: #ec4899; /* pink-500 */
    color: #ffffff; /* white */
    padding: 0.5rem 1.5rem; /* px-6 py-2 */
    border-radius: 0.5rem; /* rounded-lg */
    font-size: 1.25rem; /* text-xl */
    font-weight: 600; /* font-semibold */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope', sans-serif; /* Manrope font for hero section */
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .enroll-button-hero-section:hover {
    transform: scale(1.05); /* scale-105 */
    background-color: #db2777; /* hover:bg-pink-600 */
  }




@media screen and (max-width: 600px) {
    .tech-guy-image-1 {
        top: 115%;
    }

    .html-tag-icon {
        top: 106%;
        left: 15%;
        width: 80px;
    }

    .curly-icon {
        top: 100%;
        width: 80px;
    }

    .ham-burger-icon {
        top: 106%;
        left: 85%;
        width: 80px;
    }
}



.request-callback-button {
    width: 100%;
    /* Equivalent to w-full */
    font-weight: 600;
    /* Equivalent to font-semibold */
    color: black;
    /* Equivalent to text-black */
    padding-top: 0.75rem;
    /* Equivalent to py-3 (1rem = 16px, 0.75rem = 12px) */
    padding-bottom: 0.75rem;
    border-radius: 0.5rem;
    /* Equivalent to rounded-lg (8px radius) */
    font-size: 1.125rem;
    /* Equivalent to text-lg (18px font size) */
    transition: box-shadow 0.3s ease;
    /* Equivalent to transition duration-300 */
}

/* Hover state */
.request-callback-button:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Equivalent to hover:shadow-lg */
}


.zIndexHigher-hero-section {
    position: relative;
    z-index: 20
}