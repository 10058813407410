/* CardSection.css */

.card-section-master-approach {
    /* height: 100vh; */
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 1000px;
    margin: 40px auto;
    padding: 0 20px;
    padding-top: 40px
}

.card-section-container-master-approach{
    height: 100vh;
    max-width: 100vw;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 5%; */
}

.master-approach-header{
    font-weight: 700;
    font-size: 36px ;
    line-height: 42px; 
    padding: 0 22%;
    padding-top: 10px;
}

@media screen and (max-width: 600px) {

    .card-section-container-master-approach{ 
        margin-top: 70%;
        /* position: relative;
        top:280px; */
    }

    .card-section-master-approach {
        height: auto;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        max-width: 100vw;
        margin: 40px auto;
        padding: 0 20px;
        padding-top: 40px
    }
    .master-approach-header{
        font-weight: 650;
        font-size: 28px ;
        line-height: 36px; 
        padding: 0 12%;
        padding-top: 10%;
    }

}