.curriculam-section-container {
  height: auto;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 20rem; /* equivalent to mt-20 in Tailwind */
  flex-direction: column;
  align-items: center;
  padding-bottom: 6rem; /* equivalent to pb-24 in Tailwind */
}



.curriculum-heading-container {
  text-align: center;
  padding: 60px 280px 0px 280px;
  /* background-color: #E8F7F8; */
  /* Adjust the background color to match your design */
  border-radius: 50px 50px 0px 0px;
  /* Rounded edges if required */
  width: 75%;
}

.curriculum-heading {
  font-size: 2.2rem;
  /* Adjust size based on your preference */
  font-weight: 750;
  /* Bold heading */
  color: #000000;
  /* Adjust the color as per your theme */
  margin: 0;
  line-height: 40px;
}





/* Layout Styling */
.curriculum-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 1rem;
  /* background-color: #E6F4F2; */
  height: 90vh;
  /* Full view height for proper scrolling */
  width: 75%;
  border-radius: 0px 0px 50px 50px;
  padding: 50px 50px 50px 50px;
}

.topics-list {
  flex: 1;
  min-width: 50%;
  border-radius: 8px;
}

.topic-item {
  padding: 20px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  border-radius: 30px;
  background-color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding-left: 30px;
}

.topic-item.active {
  /* background-color: #2DF8C5; */
  /* bg-gradient-to-r from-blue-300 to-cyan-500 */
  background-image: linear-gradient(to right, #add1fd, #85f1fd);
}

.topic-content {
  flex: 2;
  min-width: 300px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 30px;
  overflow-y: auto;
  /* Scroll if content is too long */
  /* height: auto; */
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.topic-content.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.pill {
  padding: 0.5rem 1rem;
  background-color: transparent;
  border-radius: 8px;
  font-size: 0.9rem;
}

.pill.essential {
  background-color: #ffecb3;
}

.colorYe {
  background-color: #ffecb3;
}


/* ----------------------------------------------------------- */
/* changing the color and size of the scrollbar for topic content */

/* Target the specific content section */
.topic-content {
  /* Add height and overflow to see the scrollbar */
  height: 73vh;
  /* Adjust height as needed */
  overflow-y: scroll;
}

/* Scrollbar styling for WebKit browsers (Chrome, Safari, Edge) */
.topic-content::-webkit-scrollbar {
  width: 12px;
  /* Change the width of the scrollbar */
}

/* Scrollbar track */
.topic-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the track */
  border-radius: 10px;
}

/* Scrollbar handle */
.topic-content::-webkit-scrollbar-thumb {
  background-color: #4CAF50;
  /* Color of the scrollbar */
  border-radius: 10px;
  border: 3px solid #ffffff;
  /* Padding inside the scrollbar */
}

/* Scrollbar handle hover effect */
.topic-content::-webkit-scrollbar-thumb:hover {
  background-color: #45a049;
  /* Darker shade on hover */
}

/* Mobile Accordion Styling ---------------------------------------------------------------------------*/
.accordion-item {
  margin-bottom: 1rem;
  width: 100%;
}

.curriculum-container {
  padding: 20px 20px 20px 20px;
  height: auto;
  padding-top: 40px;
}

.accordion-title {
  background-color: #ffffff;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.accordion-title.active {
  /* background-color: #00bfa5; */

  background-image: linear-gradient(to right, #add1fd, #85f1fd);
  color: #ffffff;
  border-radius: 30px 30px 0px 0px;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  overflow: auto;
  max-height: 250px;
  /* Enough to open the content */
  transition: max-height 0.5s ease;
  background-color: white;
  padding: 10px;
  border-radius: 0 0 30px 30px;
}

.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
  width: 100%;
}

.enrollIcon {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #FFD500;
  color: black;
  padding: 16px 24px 16px 24px;
  font-weight: 600;
  border-radius: 8px;
  font-size: 18px;
  line-height: 28px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.enrollIcon:hover {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* .manrope-curriculam-section {
  font-family: "Manrope", sans-serif;
}

.rubik-curriculam-section {
  font-family: "Rubik", sans-serif;
} */

@media screen and (max-width: 800px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
    width: 100%;
  }

  .curriculum-heading-container {
    padding: 50px 50px 0px 50px;
  }

  .curriculum-heading {
    font-size: 25px;
  }


  .curriculum-container {
    flex-direction: column;
  }

  .pill-container {
    justify-content: center;
  }
}


@media screen and (max-width: 600px) {

  .curriculum-heading-container {
    padding: 50px 20px 0px 20px;
    width: 87%;
  }

  .curriculum-heading {
    font-size: 21px;
    line-height: 25px;
  }

  .curriculum-container {
    width: 87%;
  }

  .accordion-title {
    padding: 20px 20px 10px 20px;
    border-radius: 30px;
  }

  .mobile-view {
    width: auto;
  }

  .svgIcon {
    border: 2px solid #B9CFCA;
    padding: 2px 5px 2px 5px;
    border-radius: 9999px;
    background-color: #B9CFCA;
  }

  .pill-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .pill {
    padding: 3px 7px 3px 7px;
    background-color: #e0f2f1;
    border-radius: 8px;
    font-size: 13px;
    border: 2px solid #535352;
    font-weight: 500;
  }

  .fundamentals {
    border-radius: 20px;
    padding: 10px;
  }

  .essentials {
    border-radius: 20px;
    padding: 10px;
  }
  

}