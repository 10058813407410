.faq-item-mobile {
    background: linear-gradient(to right, #ebf8ff, #cffafe);
    padding: 22px 25px;
    border-radius: 9999px;
    margin-bottom: 15px;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .faq-item-mobile {
        border-radius: 50px;
    }
}