/* UserIcon.css */
.user-icon-navbar {
  width: 50px; /* Slightly larger size */
  height: 50px;
  background: linear-gradient(135deg, #3498db, #2ecc71); /* Subtle gradient for a more modern look */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Perfect circle */
  font-size: 24px; /* Slightly larger font */
  font-weight: bold;
  text-transform: uppercase; /* Uppercase for a professional look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  cursor: pointer;
}

.user-icon-navbar:hover {
  transform: scale(1.1); /* Slight zoom-in effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}



/* ///////////////////////////////////////////////////////////////// */


/* Dropdown Menu */
.dropdown-container-navBar {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
  width: 8rem;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem; /* Rounded corners */
  padding: 0.5rem 0; /* Top and bottom padding for spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10; /* Ensure it appears on top */
}

/* Profile Link */
.dropdown-link-navBar {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  color: black;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.dropdown-link-navBar:hover {
  background-color: #f7f7f7; /* Subtle hover effect */
}

/* Logout Button */
.dropdown-button-navBar {
  width: 90%; /* Almost full width */
  padding: 0.5rem 1rem;
  background-color: #e3342f;
  color: white;
  font-weight: 600;
  border-radius: 0.375rem;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  margin-top: 0.5rem; /* Space between items */
}

.dropdown-button-navBar:hover {
  background-color: #cc1f1a; /* Darker red on hover */
}

.dropdown-button-navBar:disabled {
  background-color: #f5a2a2; /* Lighter shade for loading state */
  cursor: not-allowed;
}



.message-container {
  position: absolute;
  top: 10%;
  right: 10%;
  width: auto;
  max-width: 300px;
  z-index: 100;
}


