.profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darkened overlay for better focus on modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.profile-container {
    background-color: #f9f9f9;
    padding: 30px;
    width: 100%;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.4);
    position: relative;
    font-family: Arial, sans-serif;
    color: #333;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #ff5252;
}

.profile-overlay h2 {
    margin-top: 0;
    color: #003366;
    font-size: 1.8rem;
    font-weight: 600;
}

.profile-overlay h3 {
    color: #003366;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 20px;
}

.profile-overlay label {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.profile-overlay input[type="text"],
.profile-overlay input[type="email"],
.profile-overlay input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    transition: border-color 0.3s ease;
}

.profile-overlay input[type="text"]:focus,
.profile-overlay input[type="email"]:focus,
.profile-overlay input[type="password"]:focus {
    border-color: #003366;
    outline: none;
}

.profile-overlay button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-overlay button[type="submit"]:hover {
    background-color: #005bb5;
}

.error,
.message {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}
