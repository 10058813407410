.container-placement-assistance {
    height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.card-section-placement-assistance {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 1000px;
    margin: 40px auto;
    padding: 0 20px;
    padding-top: 40px;
}


.placement-assistance-header {
    padding: 0 20%;
    font-weight: 700;
}

.span-placement-assistance {
    color: #019379;
}

.para-placement-assistance {
    margin-top: 3%;
    color: #7B8B97;
}

@media screen and (max-width: 600px) {
    .card-section-placement-assistance {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        max-width: 100%;
        margin: 40px auto;
        padding: 0 20px;
        padding-top: 40px;
    }

    .container-placement-assistance {
        margin-top: 60%;
    }


    .placement-assistance-header {
        padding: 0 11%;
        font-size: 27px;
    }

    .para-placement-assistance {
        padding: 0 3%;
    }
}
