/* Card.css */

.card-master-approach {
    /* background-color: #e6f5f2; */
    border-radius: 28px;
    padding: 24px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.card-master-approach:hover {
    transform: translateY(-10px);
}

.card-icon-master-approach {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
}

.card-master-approach h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.card-master-approach p {
    color: #666;
    font-size: 15px;
    line-height: 1.5;
}

.card-master-approach p span {
    color: #07634C;
    font-weight: bold;
}


@media screen and (max-width: 600px) {
    .card-master-approach{
        display: flex;
        justify-content: space-between;


        padding: 16px;
        text-align: left;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
    }
    .master-card-content{
        height: auto;
        width: 77%;
    }
    .card-master-approach h3 {
        font-size: 15px;
        color: #333;
        margin-bottom: 7px;
    }
    .card-master-approach:hover {
        transform: translateY(0px);
    }
}