.footer {
    background-color: #0d1b2a;
    /* Dark background */
    color: #ffffff;
    padding: 40px 20px;
    text-align: center;
    position: relative;
    bottom: 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.footer-logo .logo {
    width: 150px;
    margin-bottom: 8px;
}

.footer-logo p {
    font-size: 16px;
    color: #ccc;
    margin: 0;
}

.footer-contact h4 {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 8px;
}

.footer-contact p {
    font-size: 15px;
    color: #aaa;
    margin: 0;
}

.footer-social a {
    margin: 0 8px;
    color: #ffffff;
    font-size: 18px;
    transition: color 0.3s;
}

.footer-social a:hover {
    color: #1da1f2;
    /* Slight color change on hover */
}

.footer-bottom {
    margin-top: 20px;
    font-size: 13px;
    color: #777;
    text-align: center;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-logo,
    .footer-contact,
    .footer-social {
        margin-bottom: 15px;
    }


    .footer-logo .logo {
        width: 250px;
    }
}


.privacy-policy-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .privacy-policy-link:hover {
    text-decoration: underline;
  }