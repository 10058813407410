/* LogoCarousel.css */

.company-logos {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    width: 100%;
    padding: 0 10px;
}

.logos-row {
    display: flex;
    align-items: center;
}

.left-scroll,
.right-scroll {
    width: 200%; /* Double width for the infinite scroll effect */
}

.left-scroll img,
.right-scroll img {
    width: 100%;
    max-width: none; /* Prevents images from shrinking too much */
    object-fit: contain;
}

/* Left scroll animation */
.left-scroll {
    animation: scrollLeft 30s linear infinite;
}

/* Right scroll animation */
.right-scroll {
    animation: scrollRight 30s linear infinite;
}

/* Keyframes for left-to-right scroll */
@keyframes scrollLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}

/* Keyframes for right-to-left scroll */
@keyframes scrollRight {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(-50%);
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .company-logos {
        gap: 5px;
    }

    /* Adjust scroll speed on smaller screens */
    .left-scroll {
        animation: scrollLeft 40s linear infinite;
    }

    .right-scroll {
        animation: scrollRight 40s linear infinite;
    }

    .logos-row img {
        width: 90%; /* Scale down images for smaller screens */
    }
}

@media (max-width: 480px) {
    .company-logos {
        gap: 3px;
    }

    .left-scroll {
        animation: scrollLeft 45s linear infinite;
    }

    .right-scroll {
        animation: scrollRight 45s linear infinite;
    }

    .logos-row img {
        width: 220%;
    }
}
