/* Ensure the container is spaced properly */
.phone-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  /* Style the input to avoid overlap */
  .phone-input {
    padding-left: 65px; /* Adjust padding-left to accommodate the wider flag dropdown */
    height: 45px;
    font-size: 1rem;
  }
  /* Style the flag dropdown */
  .flag-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px; /* Increase width to fully contain the flag */
    height: 100%;
    border: none;
    display: flex;
    align-items: center; /* Vertically center the flag */
    justify-content: center; /* Horizontally center the flag */
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  /* Additional styles (optional) */
  .phone-input:focus {
    border-color: #3b82f6; /* Example focus color */
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5); /* Focus outline */
  }
  

  .bulbIcon{
    position: relative;
    z-index: 20;
    height: 56px;
    width: 32px;
    bottom: 50%;
    left: 60%;
  }