.chat-button {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-image: linear-gradient(to bottom right, #ff3cac, #562b7c, #2b86c5);
    padding: 2px;
}

.chat-button img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}




.header-chat-bot {
    font-weight: 500;
}




.chat-message {
    position: absolute;
    bottom: 70px;
    right: 0;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 10px;
    font-weight: 600;
    width: 170px;
    height: 50px;
}

.chat-message::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
}

.chat-popup {
    position: fixed;
    bottom: 90px;
    right: 15px;
    width: auto;
    background-color: transparent;
    border-radius: 8px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    z-index: 1000;
}

/* .popup-option {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #ffcc80;
    padding: 10px 15px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.2s;
}

.popup-option:hover {
    background-color: #ffb74d;
}

.popup-icon {
    font-size: 16px;
} */

.popup-close {
    align-self: center;
    background-color: #f0f0f0;
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-close:hover {
    background-color: #e0e0e0;
}


/* //////////////////////////////////////////////////////////////////// */

/* Modal Styles */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dim background */
    z-index: 999;
    /* Behind modal but above other content */
}

.callback-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #013536;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    color: white;
    z-index: 1000;
}

.modal-close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}

.callback-modal h2 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.modal-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.modal-input label {
    font-size: 14px;
    color: #9fb3b6;
    margin-bottom: 5px;
}

.modal-input input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #234e49;
    color: white;
    font-size: 18px;
}

.modal-submit {
    width: 100%;
    padding: 10px;
    background-color: #ffcc00;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.modal-submit:hover {
    background-color: #ffb700;
}


@media screen and (max-width: 600px) {
    .callback-modal {
        width: 90%;
        padding: 30px;
    }

    .modal-input input {
        padding: 4px;
        padding-left: 10px;
        height: 40px;
    }

    .modal-close {
        position: absolute;
        top: 28px;
        right: 37px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
    }

}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



/* ////////////////////////////////////////////////////// */


/* Wave Animation */
@keyframes wave {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 128, 255, 0.4);
        /* Reduced opacity */
    }

    50% {
        box-shadow: 0 0 15px 15px rgba(0, 128, 255, 0.3);
        /* Smaller spread and less opacity */
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 128, 255, 0.4);
    }
}

.wave {
    animation: wave 1.5s ease-out infinite;
    /* Slightly shorter duration */
}