.login-page-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #ebf8ff, #bee3f8, #b2f5ea); /* Adjust colors as needed */
    padding: 1.5rem; /* 6 in Tailwind terms */
}

@media screen and (max-width: 600px) {
    .login-page-container{
        max-height: 100vh;
    }
}